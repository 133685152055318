import React, { Component, Fragment } from "react";
import {
    Form,
    Alert,
} from 'antd';
import './HSCInputContainer.less'
import HSCInput from "./HSCInput";



export default class HSCInputContainer extends Component {
    state = {
        error: null
    };

    render() {
        let columns = ""
        let margenIzquierdo = ""
        let margenDerecho = ""
        
        if (this.props["-columnas"] !== undefined) {
            columns = "hsc-columns-" + this.props["-columnas"];
        } else {
            columns = "hsc-columns-1"
        }

        if (this.props["-margen-izquierdo"] !== undefined) {
            margenIzquierdo = "hsc-left-margin-" + this.props["-margen-izquierdo"];
        }
        if (this.props["-margen-derecho"] !== undefined) {
            margenDerecho = "hsc-right-margin-" + this.props["-margen-derecho"];
        }
        let fieldClassName = `${columns} ${margenIzquierdo} ${margenDerecho}`;
        let fieldContent = null
        if (this.state.error == null) {
                fieldContent =
                        <HSCInput 
                            throwComponentError={this.props.throwComponentError}
                            {...this.props}
                        />
        }
        else {
            fieldContent = <Form.Item className={`hsc-form-item-container ${fieldClassName}`}>
                <Alert message={this.state.error} type="error" showIcon />
            </Form.Item>;
        }
        return (
            <Fragment>
                {fieldContent}
            </Fragment>
        );
    }
}