import React, { Component } from "react";
import { saveAs } from 'file-saver';
import {Skeleton, Form, Button} from 'antd';
import HSCInputContainer from "./HSCInputContainer";
import _ from 'lodash'

export default class HSCDownloader extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            renderForm:false,
            downloadedFields:[]
        }
        this.requiredFields = [];
        this.usedFields=[];
    }

    validateFields= () => {
        if(this.requiredFields.length>0 && !_.isEqual(this.requiredFields.sort(), this.usedFields.sort())){
            this.props.showNotificationMessage("Error", "No se han descargado todos los archivos", "error", false);
            return;
        }
        this.props.validateChangeNextLevel(this.props.currentComponent);
    }

    saveFile = (fileData, name, field) => {
        saveAs(fileData,name);
        if(field["-obligatorio"]!=null && field["-obligatorio"]===true && !this.usedFields.includes(field["-name"])){
            this.usedFields.push(field["-name"]);
            let {downloadedFields} = this.state;
            downloadedFields[field["-name"]]=true;
            this.setState({downloadedFields})
        }
    }

    downloadFile = (field) =>{
        let formulas = [];
        if(field["-archivo"]!=null && field["-archivo"]!==""  && field["-archivo"]!==undefined){
            formulas=[
                {
                    name:"nombreArchivo",
                    formula:field["-nombreArchivo"]!=null && field["-nombreArchivo"]!=="" ? field["-nombreArchivo"]:""
                }
            ];
            let formulaFetch = this.props.syncronouslyEvaluateFormulas(`${this.props.FORMULA_API_HOST}/formulas`,
            formulas,
                this.props.getModelObject()
                )
                formulaFetch.then((fields) => {
                    // let fileGet = this.props.getTaskFileModel();
                    // fileGet.then((resObj) => {
                    //     let file = field["-archivo"];
                    //     if(fields.formulas[0].error!=null && fields.formulas[0].error!=undefined)
                    //         this.props.throwComponentError("Ocurrio un Error con la formula ["+fields.formulas[0].formula+"]: " + fields.formulas[0].error.errorStack);
                    //     let fileName = fields.formulas[0].value;
                    //     let rexp = new RegExp(/%\w+\.\w+\.\w+%/, 'gm');
                    //     if (!file.match(rexp))
                    //         this.props.throwComponentError("el archivo ["+file+"] no cuenta con la estructura correcta [%idNivel.idComponente.campo%]");
                    //     rexp = new RegExp(/%/, 'gm');
                    //     file = file.replace(rexp, "");
                    //     if(resObj!=null && resObj[file]!=null && resObj[file].valor!=null && resObj[file].valor instanceof Blob)
                    //         this.saveFile(resObj[file].valor,fileName!=null && fileName!="" ? fileName+"."+resObj[file].tipo:"archivo."+resObj[file].tipo,field);
                    // });

                    let fileModel = this.props.getTaskFileModel();
                        let file = field["-archivo"];
                        if(fields.formulas[0].error!==null && fields.formulas[0].error!==undefined)
                            this.props.throwComponentError("Ocurrio un Error con la formula ["+fields.formulas[0].formula+"]: " + fields.formulas[0].error.errorStack);
                        let fileName = fields.formulas[0].value;
                        let rexp = new RegExp(/%\w+\.\w+\.\w+%/, 'gm');
                        if (!file.match(rexp))
                            this.props.throwComponentError("el archivo ["+file+"] no cuenta con la estructura correcta [%idNivel.idComponente.campo%]");
                        rexp = new RegExp(/%/, 'gm');
                        file = file.replace(rexp, "");
                        if(fileModel!=null && fileModel[file]!=null && fileModel[file].valor!=null && fileModel[file].valor instanceof Blob)
                            this.saveFile(fileModel[file].valor,fileName!=null && fileName!=="" ? fileName+"."+fileModel[file].tipo:"archivo."+fileModel[file].tipo,field);
                });
        }
        else if(field["-convertir-a-archivo"]!==null && field["-convertir-a-archivo"]!=="" && field["-convertir-a-archivo"]!==undefined){
            formulas=[
                {
                    name:"nombreArchivo",
                    formula:field["-nombreArchivo"]!=null && field["-nombreArchivo"]!=="" ? field["-nombreArchivo"]:""
                },
                {
                    name:"extension",
                    formula:field["-extension"]!=null && field["-extension"]!=="" ? field["-extension"]:""
                }
            ];
            let modelObject={
                modelo:this.props.getModelObject()
            }
            let formulaFetch = this.props.syncronouslyEvaluateFormulas(`${this.props.FORMULA_API_HOST}/formulas`,
            formulas,
                this.props.getModelObject()
                );
            let fileResolve = this.props.syncronouslyConvertStringToFile(field["-extension"], `${this.props.NON_INTERACTIVE_HOST}/files/transformModelToBlob`,
            field["-convertir-a-archivo"],
                modelObject
                );
                formulaFetch.then((fields) => {
                    if(fields!=null && fields.formulas!=null){
                        let finalName="archivo";
                        let extension = "txt";
                        for(let i=0;i<fields.formulas.length;i++){
                            let formula = fields.formulas[i];
                            if(formula.error!=null && formula.error!==undefined)
                                this.props.throwComponentError("Ocurrio un Error con la formula ["+formula.formula+"]: " + formula.error.errorStack);
                            switch(formula.name){
                                case "nombreArchivo":
                                    finalName=formula.value!=null && formula.value!=="" ? formula.value:"archivo";
                                    break;
                                    case "extension":
                                        extension=formula.value!=null && formula.value!=="" ? formula.value:"txt";
                                        break;
                            }   
                        }
                        fileResolve.then((blob) => {
                            if (blob.constructor === new Object().constructor) {
                              this.props.showNotificationMessage(
                                'Ocurrió un error',
                                blob != null ? blob.message : '',
                                'error',
                                true
                              );
                            } else {
                                this.saveFile(blob, finalName+"."+extension, field);
                            }
                          })
                    }
            });
        }
        else{
            formulas=[
                        {
                            name:"grupo",
                            formula:field["-grupo"]!=null && field["-grupo"]!=="" ? field["-grupo"]:""
                        },
                        {
                            name:"expediente",
                            formula:field["-expediente"]!=null && field["-expediente"]!=="" ? field["-expediente"]:""
                        },
                        {
                            name:"nombreDocumento",
                            formula:field["-nombreDocumento"]!=null && field["-nombreDocumento"]!=="" ? field["-nombreDocumento"]:""
                        },
                        {
                            name:"nombreArchivo",
                            formula:field["-nombreArchivo"]!=null && field["-nombreArchivo"]!=="" ? field["-nombreArchivo"]:""
                        }
                    ];
            let formulaFetch = this.props.syncronouslyEvaluateFormulas(`${this.props.FORMULA_API_HOST}/formulas`,
            formulas,
                this.props.getModelObject()
                )
                formulaFetch.then((fields) => {
                let parameters = this.getParameters(fields);
                if(parameters==null){
                    this.props.throwComponentError("Ocurrio un error al obtener el archivo. Favor de checar los parametros de la descarga");
                }
                let fileGet = this.props.syncronouslyGetRepositoryFile(`${this.props.REPOSITORY_API_ROOT}/docviewer/repository/document${parameters.urlParameters}`, null, (error) => {return error});
                fileGet.then((resObj) => {
                    console.log(resObj);
                    if (resObj instanceof Blob) {
                        this.saveFile(resObj,parameters.fileName!=null && parameters.fileName!=="" ? parameters.fileName:"archivo",field);
                    }
                    else{
                        if(resObj!=null && resObj.error!=null && resObj.error!=="")
                            this.props.throwComponentError("Ocurrio un error al obtener el archivo.\n"+resObj.error);
                        else
                            this.props.throwComponentError("Ocurrio un error al obtener el archivo. Favor de checar los parametros de la descarga");
                    }
                });
            });
        }
    }

    getParameters = (fields) => {
        if(fields!=null && fields.formulas!=null){
            let path="/", type="", name="", finalName="";
            for(let i=0;i<fields.formulas.length;i++){
                let formula = fields.formulas[i];
                if(formula.error!=null && formula.error!==undefined)
                    this.props.throwComponentError("Ocurrio un Error con la formula ["+formula.formula+"]: " + formula.error.errorStack);
                switch(formula.name){
                    case "grupo":
                    case "expediente":
                        if(path!=="/")
                            path+="/"
                        path+=formula.value;
                        break;
                    case "nombreDocumento":
                        let nameSplit = formula.value.split(".");
                        name=nameSplit.slice(0,nameSplit.length-1).join("");
                        type=nameSplit[nameSplit.length-1];
                        break;
                    case "nombreArchivo":
                    default:
                        finalName=formula.value!=null && formula.value!=="" ? formula.value+"."+type:"archivo."+type;
                        break;
                }   
            }
            if(path!=="" && type!=="" && name!=="")
                return {
                    urlParameters: `?name=${name}&type=${type}&path=${path}&action=downloadResource`,
                    fileName:finalName
                }
        }
        return null
    }

    componentDidMount() {
        this.props.setComponentNextLevel(this.validateFields);
        this.setState({
            renderForm:true
        })
    }

    render() {
        let buttonColumns = "";
        let buttonText = "";
        let buttonLeftMargin = "";
        let buttonRightMargin = "";
        let buttonVisible = "";
        let inputs = null;
        if(this.state.renderForm){
            let buttonstyles = this.props.estilos != null ? this.props.estilos.boton != null ? this.props.estilos.boton : null : null;
            if (buttonstyles != null && buttonstyles["-columnas"] !== undefined) {
                buttonColumns = "hsc-columns-" + buttonstyles["-columnas"];
            } else {
                buttonColumns = "hsc-columns-1"
            }

            if (buttonstyles != null && buttonstyles["-texto"] !== undefined) {
                buttonText = buttonstyles["-texto"];
            } else {
                if (this.props.isSearchForm != null && this.props.isSearchForm === true) {
                    buttonText = "Buscar"
                }
                else {
                    buttonText = "Guardar"
                }
            }

            if (buttonstyles != null && buttonstyles["-margen-izquierdo"] !== undefined) {
                buttonLeftMargin = "hsc-left-margin-" + buttonstyles["-margen-izquierdo"];
            }
            if (buttonstyles != null && buttonstyles["-margen-derecho"] !== undefined) {
                buttonRightMargin = "hsc-right-margin-" + buttonstyles["-margen-derecho"];
            }


            if (buttonstyles != null && buttonstyles["-visible"] !== undefined) {

                buttonVisible = buttonstyles["-visible"] === false ? "hsc-invisible-element" : "";
            }
            let inputsArray = [];
            if (Array.isArray(this.props.download.campo))
                inputsArray = this.props.download.campo;
            else
                inputsArray.push(this.props.download.campo);
            inputs = inputsArray.map((field) => {
                if(field["-obligatorio"]!=null && field["-obligatorio"]===true && !this.requiredFields.includes(field["-name"]))
                    this.requiredFields.push(field["-name"])
                return <HSCInputContainer
                    throwComponentError={this.props.throwComponentError}
                    field={field}
                    downloadFile={this.downloadFile}
                    downloaded={this.state.downloadedFields[field["-name"]]}
                    {...field}
                />
            });
        }
        return (
            this.state.renderForm ?
                <Form>
                    {inputs}
                    <Form.Item label={" "} className={`hsc-form-item-container buttonContainerAfter ${buttonColumns} ${buttonLeftMargin} ${buttonRightMargin} ${buttonVisible}`}>
                    <Button
                    onClick={this.validateFields}
                     className={"hsc-primary-button-size hsc-primary-button"} type={"primary"} htmlType="button">
                        {buttonText}
                    </Button>
                </Form.Item>
                </Form>
            :
                <Skeleton active />
        )
    }
}