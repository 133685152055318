import React, { Component } from 'react'

export default class HSCLabel extends Component {


    render() {

        let classes = this.props.containerClass;
        let containerStyles = {};
        let labelStyles = {};
        let text = this.props["-text"] !== "" && this.props["-text"] !== null && this.props["-text"] !== undefined ? this.props["-text"] : this.props["-texto"];
        if (this.props["-width"] != null) {
            if (this.props["-width"] !== "" && (this.props["-width"].includes("%") ||
                this.props["-width"].includes("px") || this.props["-width"].includes("PX") ||
                this.props["-width"].includes("em") || this.props["-width"].includes("EM"))) {
                labelStyles["width"] = this.props["-width"];
            }
            else {
                labelStyles["width"] = this.props["-width"].toString() + "px";
            }
        }
        if (this.props["-tamaño"] !== null) {
            if (this.props["-tamaño"] !== "" && (this.props["-tamaño"].includes("%") ||
                this.props["-tamaño"].includes("px") || this.props["-tamaño"].includes("PX") ||
                this.props["-tamaño"].includes("em") || this.props["-tamaño"].includes("EM"))) {
                labelStyles["font-size"] = this.props["-tamaño"];
            }
            else {
                labelStyles["font-size"] = this.props["-tamaño"].toString() + "px";
            }
        }
        if (this.props["-color"] != null) {
            labelStyles["color"] = this.props["-color"];
        }
        if (this.props["-bold"] != null) {
            labelStyles["font-weight"] = this.props["-bold"];
        }
        if (this.props["-interlineado"] != null) {
            labelStyles["line-height"] = this.props["-interlineado"];
        }


        if (this.props["-clase"] != null) {
            classes += " " + this.props["-clase"] + " ";
        }
        switch (this.props["-alineacion"]) {
            case "izquierda":
            case "izquierdo":
            case "left":
                containerStyles["text-align"] = "left";
                break;
            case "derecha":
            case "derecho":
            case "right":
                containerStyles["text-align"] = "right";
                break;
            case "centro":
            case "center":
                containerStyles["text-align"] = "center";
                break;
            default:
                containerStyles["text-align"] = "left";
                break;
        }

        let label = <span style={labelStyles}>{text}</span>;


        return (
            <div className={classes} style={containerStyles}>
                {label}
            </div>
        );

    }
}