import React, { Component, Fragment } from "react";
import {
    Form,
    Skeleton,
} from 'antd';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import HSCLabel from "./HSCLabel";
import HSCDownLoadButton from "./HSCDownLoadButton";

export default class HSCInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmDirty: false,
            autoCompleteResult: [],
            fieldMetadata: null,
            error: null,
            value: null,
            fieldMetadataFK: null,
        };
    }

    isHSCLabelType = (type) => {
        return type === "etiqueta" || type === "label"
    }

    render() {
        let columns = ""
        let margenIzquierdo = ""
        let margenDerecho = ""
        let inputLabel = this.props["-text"];
        let labelPisicion = this.props["-posicionTexto"];

        let textPosition = "label-left";

        if (labelPisicion !== null && labelPisicion !== "" && (labelPisicion === "derecha" || labelPisicion === "right")) {
            textPosition = "label-right";
        }
        else if (labelPisicion !== null && labelPisicion !== "" && (labelPisicion === "centro" || labelPisicion === "center")) {
            textPosition = "label-center";
        }

        if (this.props["-columnas"] !== undefined) {
            columns = "hsc-columns-" + this.props["-columnas"];
        } else {
            columns = "hsc-columns-1"
        }

        if (this.props["-margen-izquierdo"] !== undefined) {
            margenIzquierdo = "hsc-left-margin-" + this.props["-margen-izquierdo"];
        }
        if (this.props["-margen-derecho"] !== undefined) {
            margenDerecho = "hsc-right-margin-" + this.props["-margen-derecho"];
        }

        let downloadIcon = null;

        if(this.props["-obligatorio"]!=null && this.props["-obligatorio"]===true){
            if(!this.props.downloaded)
                downloadIcon = <ExclamationCircleOutlined
                 style={{"color": "red",
                         "font-size": "24px",
                         "margin-left": "5px",
                         "top": "13%",
                         "position": "relative"
                 }}
                />
            else
            downloadIcon = <CheckCircleOutlined
                style={{"color": "green",
                        "font-size": "24px",
                        "margin-left": "5px",
                        "top": "13%",
                        "position": "relative"
                }}
            />
        }


        let styleInput = {};

        let fieldClassName = `${columns} ${margenIzquierdo} ${margenDerecho}`;

        let fieldContent = null;
            let isLabel = false;
            let inputComponent = null;
            if (this.props["-type"] !== undefined) {
                if (this.isHSCLabelType(this.props["-type"])) {
                    isLabel = true;
                    inputComponent = <HSCLabel containerClass={`hsc-form-item-container ${fieldClassName}`} {...this.props} />;
                }
            }
            else {
                inputComponent = <HSCDownLoadButton
                downloadFile={this.props.downloadFile}
                field={this.props.field}
                />
            }
            if (inputComponent != null && !isLabel) {

                fieldContent = <Form.Item initialValue={this.state.value !== null && this.state.value !== undefined ? this.state.value : null} validateStatus={this.props.validationStatus} hasFeedback className={`hsc-form-item-container ${fieldClassName} ${textPosition}`} label={inputLabel} style={styleInput}>
                    {inputComponent}{downloadIcon}
                </Form.Item>;
            }
            else if (isLabel) {
                fieldContent = inputComponent;
            }
        else {
            fieldContent = <Form.Item validateStatus={this.props.validationStatus} hasFeedback className={`hsc-file-manager-item-container hsc-form-item-container ${fieldClassName} ${textPosition}`} style={styleInput}>
                <Skeleton className="hsc-form-item-placeholder" active paragraph={{ rows: 1, width: "100%" }} />
            </Form.Item>;
        }
        return (
            <Fragment>
                {fieldContent}
            </Fragment>
        );
    }
    // }
}