import React from 'react';
import ReactDOM from 'react-dom';
import HSCDownloader from './HSCDownloader/HSCDownloader';
import 'antd/dist/antd.css'

// uncomment this block to "defer" ReactDOM.render and expose it globaly
window.HSCDownloader = {
    mount: (props, container) => {
        ReactDOM.render(<HSCDownloader {...props} />, container);
    },
    unmount: (container) => {
        ReactDOM.unmountComponentAtNode(container);
    }
}


console.log("HSCDownloader version: v1.0.0.5")